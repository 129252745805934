
class Logger
{
    start = null;

    constructor()
    {
        this.start = new Date();
        this._debug = true;
    }

    log()
    {
        if (!this._debug) return;
        var end = new Date() - this.start;
        console.log('ff-testing: ', 'Time from page load = ', end, ...arguments);
    }
    
    warn()
    {
        if (!this._debug) return;
        console.warn('ff-testing: ', ...arguments);
    }

    err()
    {
        if (!this._debug) return;
        console.error('ff-testing: ', ...arguments);
    }
}

export default Logger;