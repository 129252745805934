import Logger from '../services/utils/log.js';
import { LoadFlickfusionScriptForThisDomain } from '../helpers/appHelper'
import { IsDealerCRM } from '../helpers/appHelper';

const Log = new Logger();

function loadJS(file) {
    Log.log('load_scripts.js - loading file', file);
    var jsElm = document.createElement("script");
    jsElm.type = "application/javascript";
    jsElm.src = file;
    if (document.body) {
        document.body.appendChild(jsElm);
    } else {
        document.addEventListener('DOMContentLoaded', function () {
            document.body.appendChild(jsElm);
        });
    }
}

function loadFlickfusionScript() {
    if(LoadFlickfusionScriptForThisDomain() && !IsDealerCRM()) {
        loadJS('https://cdn.flickfusion.net/s/js/pe.js');
    }
}

function loadFlowfoundScript() {
    if (!IsDealerCRM()) {
        loadJS('https://vrplayer.flowfound.com/services/360.js');
    }
}

module.exports = {
    loadFlowfoundScript,
    loadFlickfusionScript
};