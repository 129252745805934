import { loadFlickfusionScript, loadFlowfoundScript } from './src/other/load_scripts';
import Logger from './src/services/utils/log.js';

const Log = new Logger();

// If script has loaded once then don't load it again (this is to avoid when we load ff script twice on a page)
if (!window.ffScriptLoadedOnce) {
    Log.log('index.js - FF script loaded for first time');
    window.ffScriptLoadedOnce = true;
    loadFlickfusionScript();
    loadFlowfoundScript();
} else {
    Log.log('index.js - FF script has loaded once already. This script will be ignored');
}