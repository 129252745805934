export function IsDealerCRM() {
    var crms = [".dealereprocess.com", ".dealer.com", ".dealeron.com", ".sincrotools.com"];
    return IsOneOfDomains(crms);
}

export function LoadFlickfusionScriptForThisDomain() {
    const loadOnlyForFollowingKeywords = ['ff-'];
    var urlPath = window.location.pathname;
    for (const keyword of loadOnlyForFollowingKeywords) {
        if (urlPath.indexOf(keyword) > -1) {
            return true;
        }
    }
    return false;
}

export const MediaTypeEnum = {
    Omnivirt: 1,
    SteponeYoutube: 2,
    FlowfoundAWS: 3,
    GermainYoutube: 4,
}

export const PageTypeEnum = {
    All: 1,
    ThreeSixtyLandingPage: 2,
    ResearchLandingPage: 4,
    VDP: 8,
    SRP: 16
}

export function IsFlickfusionScriptLoaded() {
    return window.flickfusionScript;
}

export function DoNotLoadScriptByUrl() {
    var dontLoadScriptForThisPage = false;
    const dontLoadForFollowingKeywords = ['ff-', 'new', 'used', 'inventory', 'certified', 'view', 'owned', 'search', 'cpo'];
    var urlPath = window.location.pathname;
    for (const keyword of dontLoadForFollowingKeywords) {
        if (urlPath.indexOf(keyword) > -1) {
            dontLoadScriptForThisPage = true;
            break;
        }
    }
    
    return dontLoadScriptForThisPage;
}

const IsOneOfDomains = (domains) => {
    var host = window.location.host;
    var result = false;

    for (var i=0; i<domains.length; i++) {
        var domain = domains[i];
        if (host.indexOf(domain) > -1) {
            result = true;
            break;
        }
    }

    return result;
}

// Get vendor information (for local development use flowfound.com else real vendor)
export function getVendorUrl() {
    const vendorUrl = (process.env.NODE_ENV === 'LOCAL_DEV' ? 'flowfound.com' : window.location.host)
    return vendorUrl;
}